/**
* Helper path
*/
$assets-path: '../../..';


/**
 * Color
 */

$clr-primary-dark: #180038;
$clr-primary     : #340078;
$clr-primary-l8  : #481091;
$clr-primary-l7  : #764DAB;
$clr-primary-l6  : #9F82C4;
$clr-primary-l5  : #9F82C4;
$clr-primary-l4  : #C3AFDE;
$clr-primary-l3  : #C3AFDE;
$clr-primary-l2  : #D4CCDE;
$clr-primary-l1  : #F3F0F7;

$clr-valid-light: #7DE340;
$clr-valid      : #56C414;
$clr-valid-dark : #317806;
$clr-warn       : #C49D14;
$clr-warn-dark  : #785E06;
$clr-error-light: #E34040;
$clr-error      : #C41415;
$clr-error-dark : #780607;

$clr-decontraction: #C9DAF8;
$clr-diet         : #B6D7A8;
$clr-foncier      : #FFF2CC;
$clr-velocite     : #F9CB9C;
$clr-renfo        : #E6B8AF;
$clr-muscu-velo   : #A64D79;
$clr-rythme       : #FFD966;
$clr-PMA          : #FF0000;
$clr-resistance   : #000000;
$clr-force        : #674EA7;
$clr-explosivite  : #6AA84F;
$clr-simulation   : #FF9900;
$clr-deblocage    : #A4C2F4;
$clr-musculation  : #999999;
$clr-repos        : #980000;

$clr-orange   : #FF9900;

$clr-white : #FFFFFF;
$clr-grey-1: #F6F5F7;
$clr-grey-2: #C6C1CC;
$clr-grey-3: #A19DA6;
$clr-grey-4: #88858C;

//-- Def

$clr-title   : $clr-primary-dark;
$clr-text    : $clr-primary-dark;
$clr-subtext : $clr-primary-dark;


/**
 * DEFs
 */
$workingTypes: ("DECONTRACTION": $clr-decontraction, "DIET_TRAINING": $clr-diet, "FONCIER": $clr-foncier, "VELOCITE": $clr-velocite, "RENFORT_VELO": $clr-renfo, "MUSCU_VELO": $clr-muscu-velo, "RYTHME": $clr-rythme, "PMA": $clr-PMA, "RESISTANCE": $clr-resistance, "FORCE": $clr-force, "EXPLOSIVITE": $clr-explosivite, "SIMULATION": $clr-simulation, "DEBLOCAGE": $clr-deblocage, "MUSCULATION": $clr-musculation, "REPOS": $clr-repos);
$temperatures: #264CFF, #3FA0FF, #72D8FF, #AAF7FF, #E0FFFF, #FFFFBF, #FFE099, #FFAD72, #F76D5E, #D82632, #A50021;


/**
 * Font
 */

$scr-min_width: 320px;
$scr-max_width: 1200px;

$fnt-title: 2.1rem;
$fnt-subtitle: 1.6rem;
$fnt-text: 1rem;
$fnt-subtext: 0.8rem;


/**
 * media queries
 */
$mq-desktop-md: 1440px;
$mq-desktop-xl: 1920px;
$mq-desktop: 960px;
$mq-tablet: 768px;
$mq-mobile: 600px;
$mq-mobile-sm: 480px;

$media-desktop: "only screen and (min-width : #{$mq-desktop})";
$media-tablet: "only screen and (min-width : #{$mq-tablet})";
$media-mobile: "only screen and (min-width : #{$mq-mobile})";
$media-mobile-sm: "only screen and (min-width : #{$mq-mobile-sm})";


/**
* Animations
*/
$easing-standard    : cubic-bezier(0.4, 0.0, 0.2, 1);
$easing-deceleration: cubic-bezier(0.0, 0.0, 0.2, 1);
$easing-acceleration: cubic-bezier(0.4, 0.0, 1.0, 1);
$easing-sharp       : cubic-bezier(0.4, 0.0, 0.6, 1);

$duration-long    : 987ms;
$duration-complex : 375ms;
$duration-entering: 225ms;
$duration-exiting : 195ms;
