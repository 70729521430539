/* Dashboard top page */

.m-renseignement {
    margin: 1.6rem 0;
}

.m-renseignement__completed {
    align-items: center;
    background-color: rgba($clr-valid, 0.13);
    border-radius: 34px;
    color: $clr-valid;
    display: inline-flex;
    font-weight: 600;
    font-size: $fnt-text;
    justify-content: center;
    padding: 0.5rem 1.3rem;
    margin: auto;

    img {
        width: 1.6rem;
        height: 1.6rem;
        margin-right: 0.8rem;
    }
}
