@use "sass:math";

$skewSize: 30;

//

.m-point {
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    position: relative;
    @include layered-shadow($clr-primary-dark);
    /* &:after {
        content: "";
        position: absolute;
        bottom: 0.25em;
        height: 100%;
        width: 90%;
        left: 5%;
        right: 5%;
        @include layered-shadow($clr-primary-dark);
        z-index: -1;
    } */
}

.m-point__block {
    background-color: $clr-white;
    position: relative;
    text-align: center;
    transform: skew(-#{$skewSize}deg);
    padding: 1rem 0 1.2rem;
    width: 75%;
    /* box-shadow: 0 0 5px rgba($clr-primary-dark, 0.21); */
    @include layered-shadow($clr-primary-dark);
}

.m-point__block__inner {
    transform: skew(#{$skewSize}deg);
}

.m-point__block--left {
    padding-left: #{math.div($skewSize, 2)*2}px;
    margin-left: -#{$skewSize + math.div($skewSize, 2)}px;
    margin-right: 4px;
    border-right: 4px solid $clr-primary-dark;
}
.m-point__block--right {
    padding-right: #{math.div($skewSize, 2)*2}px;
    margin-right: -#{$skewSize + math.div($skewSize, 2)}px;
    margin-left: 4px;
    border-left: 4px solid $clr-primary-dark;
}

//

.m-point__titlePoint {
    color: $clr-primary-dark;
    font-size: $fnt-title;
    font-weight: 500;
    font-style: italic;
    letter-spacing: -0.10rem;
}
.m-point__text {
    color: $clr-grey-2;
    font-size: $fnt-text;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 0.08rem;
}
