.l-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
    box-sizing: border-box;
}

@media (min-width: 415px) {
    .l-container {
        width: 85%;
        padding: 0;
    }
}
