.iv-calendar {

    /**
    * HEADER
    */
    .header {
        background-color: $clr-white;
        padding: 0.8rem 0 0 0;
        position: relative;
        margin: 0 -20px;
        @include layered-shadow($clr-primary-dark);

        &::after {
            content: "";
            position: absolute; top: 0; left: -20px; // overide margin
            width: calc(100% + 20px); height: 100%;
            background-color: $clr-white;
            z-index: -1;
        }

        @media (min-width: 415px) {
            margin: 0;
            &::after {
                left: 0;
                width: 100%;
            }
        }

    }

    .day {
        display: grid;
        grid-template-columns: repeat(9, 1fr);
    }
    .day__item {
        text-align: center;
    }
    .day__item__text {
        font-size: 1.2rem;
    }
    .day__item__date {
        font-size: 1.6rem;
        font-weight: 700;
        line-height: 1.13;
        letter-spacing: -0.13rem;
    }
    .day__button {
        margin: auto;
        height: 3.2rem;
        width: 2.1rem;
        padding: .55rem;
        transition: transform 89ms ease;
        //border: 1px solid red;

        &:active {
            transform: scale3d(1, .8, 1);
        }

        img {
            height: 100%;
            max-height: 100%;
        }
    }
    .day__item__training {
        line-height: 1;
    }
    .header__training {
        display: inline-block;
        width: 5px; height: 5px;
        border-radius: 30px;
        margin: 0 .1rem;

        &--orange { background: $clr-warn; }
        &--red { background: $clr-valid; }
    }

    .meteo {
        display: grid;
        grid-template-columns: repeat(9, 1fr);
        padding: 0.55rem 0;
        margin: .55rem 0 0 0;
        position: relative;

        &::before {
            content: '';
            position: absolute; top: 0; left: -20px;
            background-color: $clr-grey-2;
            height: 1px;
            width: calc(100% + 20px);
        }

        @media (min-width: 415px) {
            &::before {
                left: 0;
                width: 100%;
            }
        }
    }
    .meteo__item {
        margin: auto;
        width: 1.3rem; height: 1.3rem;

        &:first-child { grid-column-start: 2; }

        img {
            height: 100%;
        }
    }

    .temperature {
        display: grid;
        grid-template-columns: repeat(9, 1fr);
        padding: 0.55rem 0;
        position: relative;

        &::before {
            content: '';
            position: absolute; top: 0; left: -20px;
            background-color: $clr-grey-2;
            height: 1px;
            width: calc(100% + 20px);
        }

        @media (min-width: 415px) {
            &::before {
                left: 0;
                width: 100%;
            }
        }
    }
    .temperature__item {
        margin: auto;
        font-size: 1rem;
        font-weight: 600;
        line-height: 1.13;

        &:first-child { grid-column-start: 2; }
    }


    /**
    * BODY
    */
    .body {}
    .body__item {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: stretch;
        justify-content: space-around;
    }
    .body__item__ariane {
        min-height: 100%;
        min-width: 2.1rem;
        position: relative;

        &::before,
        &::after {
            content: "";
            position: absolute;
            top: 0.89rem;
            left: 25%;
            background-color: $clr-grey-2;
            transform: translate3d(-50%, 0, 0);
        }
        &::before {
            width: .55rem;
            height: .55rem;
            border-radius: 5px;
        }
        &::after {
            width: .13rem;
            height: 100%;
        }
    }
    .body__item__heure {
        font-size: 1.55rem;
        font-weight: 600;
        letter-spacing: -0.055rem;
        margin-bottom: .55rem;
    }
    .body__item__carte {
        background-color: $clr-white;
        padding: 1.2rem 1.6rem;
        position: relative;
        @include layered-shadow($clr-orange);
        overflow: hidden;
    }
    .body__item__carte__line {
        position: absolute;
        top: 0;
        left: 0;
        width: 3px;
        height: 100%;
        background-color: $clr-orange;
    }
    .body__item__carte__decoration {
        opacity: 0.08;
        position: absolute;
        top: -5%;
        right: -5%;
        height: 80%;
        width: auto;
    }
    .body__item__carte__titre {
        color: $clr-orange;
        font-size: 1.13rem;
        font-weight: 700;
        letter-spacing: 0.055rem;
        text-transform: uppercase;
    }
    .body__item__carte__duree {
        color: $clr-grey-3;
        display: flex; align-items: center;
        font-size: 1rem;

        img { height: 1.3rem; max-width: 1.3rem; padding: 0.13rem; }
    }
    .body__item__carte__categorie {
        color: $clr-grey-3;
        display: flex; align-items: center;
        font-size: 1rem;

        img { height: 1.3rem; max-width: 1.3rem; padding: 0.13rem; }
    }
    .body__item__carte__text {
        color: $clr-primary-dark;
        font-size: 1.2rem;
        line-height: 1.3;
        letter-spacing: -0.025rem;
    }

}
